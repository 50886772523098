import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import Home from "./helpers/Home";
export const _frontmatter = {
  "title": "George’s Site"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Home;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Right now I think a lot about:`}</p>
    <ul>
      <li parentName="ul">{`Sustaining a healthy team`}</li>
      <li parentName="ul">{`Social justice, in context of our society and the workplace`}</li>
      <li parentName="ul">{`The principle that everything should be as simple as it can be, but not
simpler`}</li>
      <li parentName="ul">{`Web accessibility`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactjs.org"
        }}>{`React`}</a></li>
      <li parentName="ul">{`Working across 7 different time zones`}</li>
    </ul>
    <p>{`I lead a team of 9 web front-end developers around the globe. `}<strong parentName="p">{`Every single
member of the team is underrepresented in the software industry`}</strong>{` 😍.`}</p>
    <h2 {...{
      "id": "articles",
      "style": {
        "position": "relative"
      }
    }}>{`Articles`}<a parentName="h2" {...{
        "href": "#articles",
        "aria-label": "articles permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">
        <Link to="/articles/datalist-autosuggest/" mdxType="Link">
  Using the HTML Data List Element for Simple Combo Boxes
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/workplace-justice-ally/" mdxType="Link">
  Being an Ally Means Getting It Wrong Sometimes
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/reducer-patterns/part-1/" mdxType="Link">
  React.useReducer Reducer Patterns, Part 1
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/reducer-patterns/part-2/" mdxType="Link">
  React.useReducer Reducer Patterns, Part 2
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/reducer-patterns/part-3/" mdxType="Link">
  React.useReducer Reducer Patterns, Part 3
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/to-locale-date-string/" mdxType="Link">
  Formatting Dates in JavaScript
        </Link>
      </li>
      <li parentName="ul">
        <Link to="/articles/remove-unwanted-properties/" mdxType="Link">
  Remove Properties From JavaScript Objects
        </Link>
      </li>
    </ul>
    <h2 {...{
      "id": "open-source-projects",
      "style": {
        "position": "relative"
      }
    }}>{`Open Source Projects`}<a parentName="h2" {...{
        "href": "#open-source-projects",
        "aria-label": "open source projects permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">
        <a href="https://github.com/gsong/react-listitem-grid">react-listitem-grid</a>
        <p parentName="li">{`Utilities and components for responsive grid layout of list items.`}</p>
      </li>
      <li parentName="ul">
        <a href="https://github.com/gsong/python-epo-ops-client">
  python-epo-ops-client
        </a>
        <p parentName="li">{`Python API client for accessing `}<a parentName="p" {...{
            "href": "https://epo.org"
          }}>{`European Patent Office`}</a>{`’s (EPO) `}<a parentName="p" {...{
            "href": "https://www.epo.org/searching-for-patents/data/web-services/ops.html"
          }}>{`Open
Patent Services`}</a>{` (OPS).`}</p>
      </li>
      <li parentName="ul">
        <a href="https://github.com/gsong/ansible-common">ansible-common</a>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://docs.ansible.com/ansible/latest/index.html"
          }}>{`Ansible`}</a>{` roles and playbooks for setting up an Ubuntu LTS server from
scratch, and deploying web apps using `}<a parentName="p" {...{
            "href": "https://www.docker.com"
          }}>{`Docker`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <a href="https://github.com/gsong/my-first-blog">my-first-blog</a>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.docker.com"
          }}>{`Docker`}</a>{`-based development environment for `}<a parentName="p" {...{
            "href": "https://tutorial.djangogirls.org/en/"
          }}>{`Django Girls tutorial`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <a href="https://github.com/gsong/dotfiles">dotfiles</a>
        <p parentName="li">{`Curious how I set up my macOS for development and productivity?`}</p>
      </li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      